import { default as indextbOQfmBMADMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/client/[id]/index.vue?macro=true";
import { default as payoutsmskyoT8WXsMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/client/[id]/payouts.vue?macro=true";
import { default as stats6FNvJSPmNWMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/client/[id]/stats.vue?macro=true";
import { default as addnX3VRD7TsmMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/client/add.vue?macro=true";
import { default as indexYynKu8Pv0hMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/client/index.vue?macro=true";
import { default as indexN3AezaW7SCMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/deploy/index.vue?macro=true";
import { default as indexwdowYUUOnNMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/event/[id]/event-manager/index.vue?macro=true";
import { default as indexIP32HkQdzXMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/event/[id]/index.vue?macro=true";
import { default as add8TsNQm6rc5Meta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/event/add.vue?macro=true";
import { default as indexaC5BeIvYAVMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/event/index.vue?macro=true";
import { default as indexpodepM5uhhMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/index.vue?macro=true";
import { default as indexH5VeZLLfHsMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/order/[id]/index.vue?macro=true";
import { default as indexNRVRVxr0tQMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/order/index.vue?macro=true";
import { default as indexy3G88hAe0pMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/pages/[id]/index.vue?macro=true";
import { default as addOMGKzBAW6OMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/pages/add.vue?macro=true";
import { default as indexEMJgLbgtVXMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/pages/index.vue?macro=true";
import { default as indexZ57XqeuIn9Meta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/payouts/index.vue?macro=true";
import { default as indexPI3Bjd4eWUMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/place/[id]/index.vue?macro=true";
import { default as addBuG7I04HLNMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/place/add.vue?macro=true";
import { default as indexT9LiJJfvPLMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/place/index.vue?macro=true";
import { default as indexS4tanlDTOUMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/requests/index.vue?macro=true";
import { default as designFmcj20lhvtMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/settings/design.vue?macro=true";
import { default as email6JQ9Nn4iteMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/settings/email.vue?macro=true";
import { default as index82SdcAGjqzMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/settings/index.vue?macro=true";
import { default as emailSwvm695sGBMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/settings/marketing/email.vue?macro=true";
import { default as indexvW1L2UMbBiMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/settings/marketing/index.vue?macro=true";
import { default as smskyW5LnG3TOMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/settings/marketing/sms.vue?macro=true";
import { default as whatsapphWxdIE6wm3Meta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/settings/marketing/whatsapp.vue?macro=true";
import { default as indexOZh5tZqtnHMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/sponsor/[id]/index.vue?macro=true";
import { default as addaknkjElNcpMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/sponsor/add.vue?macro=true";
import { default as indexCpr9fb9EdjMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/sponsor/index.vue?macro=true";
import { default as indexUyOdpw2AKxMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/user/[id]/index.vue?macro=true";
import { default as add3W9zM3RWHaMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/user/add.vue?macro=true";
import { default as indexuuW53REQQOMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/admin/user/index.vue?macro=true";
import { default as archivei6Xbd7OInNMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/archive.vue?macro=true";
import { default as cancelxouGXimlsfMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/checkout/cancel.vue?macro=true";
import { default as indexPr1zlkGStUMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/checkout/index.vue?macro=true";
import { default as successEUb5ITeNIgMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/checkout/success.vue?macro=true";
import { default as indexDJp9YkDLC3Meta } from "/etc/nginx/sites-available/ulaznice.at/pages/client/[id]/event/index.vue?macro=true";
import { default as index7Fm9eQCC3GMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/client/[id]/index.vue?macro=true";
import { default as add3FS6LR35l5Meta } from "/etc/nginx/sites-available/ulaznice.at/pages/client/[id]/payouts/add.vue?macro=true";
import { default as indexaHvWU0rRZoMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/client/[id]/payouts/index.vue?macro=true";
import { default as settingsmBJlqEk7nZMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/client/[id]/settings.vue?macro=true";
import { default as collect5yvucuxogdMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/collect.vue?macro=true";
import { default as indexopRMGIjxNqMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/event/[slug]/index.vue?macro=true";
import { default as indexdvkfCaAkd4Meta } from "/etc/nginx/sites-available/ulaznice.at/pages/event/genre/index.vue?macro=true";
import { default as indexOA9egWcckXMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/event/tag/index.vue?macro=true";
import { default as index8YfBiXbEFWMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/event/type/index.vue?macro=true";
import { default as index0Gl3AkD8X7Meta } from "/etc/nginx/sites-available/ulaznice.at/pages/index.vue?macro=true";
import { default as loginn8MiOnwcYGMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/login.vue?macro=true";
import { default as indexF64sMDJwFRMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/page/[slug]/index.vue?macro=true";
import { default as indexv0fUhMC4peMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/payspot/[id]/index.vue?macro=true";
import { default as index9vM5B6iPQ8Meta } from "/etc/nginx/sites-available/ulaznice.at/pages/payspot/event/[id]/index.vue?macro=true";
import { default as index41pHAutQ5aMeta } from "/etc/nginx/sites-available/ulaznice.at/pages/scanner/[id]/index.vue?macro=true";
import { default as supportSv03IWJtQ2Meta } from "/etc/nginx/sites-available/ulaznice.at/pages/scanner/support.vue?macro=true";
export default [
  {
    name: "admin-client-id",
    path: "/admin/client/:id()",
    meta: indextbOQfmBMADMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/client/[id]/index.vue")
  },
  {
    name: "admin-client-id-payouts",
    path: "/admin/client/:id()/payouts",
    meta: payoutsmskyoT8WXsMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/client/[id]/payouts.vue")
  },
  {
    name: "admin-client-id-stats",
    path: "/admin/client/:id()/stats",
    meta: stats6FNvJSPmNWMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/client/[id]/stats.vue")
  },
  {
    name: "admin-client-add",
    path: "/admin/client/add",
    meta: addnX3VRD7TsmMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/client/add.vue")
  },
  {
    name: "admin-client",
    path: "/admin/client",
    meta: indexYynKu8Pv0hMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/client/index.vue")
  },
  {
    name: "admin-deploy",
    path: "/admin/deploy",
    meta: indexN3AezaW7SCMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/deploy/index.vue")
  },
  {
    name: "admin-event-id-event-manager",
    path: "/admin/event/:id()/event-manager",
    meta: indexwdowYUUOnNMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/event/[id]/event-manager/index.vue")
  },
  {
    name: "admin-event-id",
    path: "/admin/event/:id()",
    meta: indexIP32HkQdzXMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/event/[id]/index.vue")
  },
  {
    name: "admin-event-add",
    path: "/admin/event/add",
    meta: add8TsNQm6rc5Meta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/event/add.vue")
  },
  {
    name: "admin-event",
    path: "/admin/event",
    meta: indexaC5BeIvYAVMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/event/index.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexpodepM5uhhMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/index.vue")
  },
  {
    name: "admin-order-id",
    path: "/admin/order/:id()",
    meta: indexH5VeZLLfHsMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/order/[id]/index.vue")
  },
  {
    name: "admin-order",
    path: "/admin/order",
    meta: indexNRVRVxr0tQMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/order/index.vue")
  },
  {
    name: "admin-pages-id",
    path: "/admin/pages/:id()",
    meta: indexy3G88hAe0pMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/pages/[id]/index.vue")
  },
  {
    name: "admin-pages-add",
    path: "/admin/pages/add",
    meta: addOMGKzBAW6OMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/pages/add.vue")
  },
  {
    name: "admin-pages",
    path: "/admin/pages",
    meta: indexEMJgLbgtVXMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/pages/index.vue")
  },
  {
    name: "admin-payouts",
    path: "/admin/payouts",
    meta: indexZ57XqeuIn9Meta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/payouts/index.vue")
  },
  {
    name: "admin-place-id",
    path: "/admin/place/:id()",
    meta: indexPI3Bjd4eWUMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/place/[id]/index.vue")
  },
  {
    name: "admin-place-add",
    path: "/admin/place/add",
    meta: addBuG7I04HLNMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/place/add.vue")
  },
  {
    name: "admin-place",
    path: "/admin/place",
    meta: indexT9LiJJfvPLMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/place/index.vue")
  },
  {
    name: "admin-requests",
    path: "/admin/requests",
    meta: indexS4tanlDTOUMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/requests/index.vue")
  },
  {
    name: "admin-settings-design",
    path: "/admin/settings/design",
    meta: designFmcj20lhvtMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/settings/design.vue")
  },
  {
    name: "admin-settings-email",
    path: "/admin/settings/email",
    meta: email6JQ9Nn4iteMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/settings/email.vue")
  },
  {
    name: "admin-settings",
    path: "/admin/settings",
    meta: index82SdcAGjqzMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/settings/index.vue")
  },
  {
    name: "admin-settings-marketing-email",
    path: "/admin/settings/marketing/email",
    meta: emailSwvm695sGBMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/settings/marketing/email.vue")
  },
  {
    name: "admin-settings-marketing",
    path: "/admin/settings/marketing",
    meta: indexvW1L2UMbBiMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/settings/marketing/index.vue")
  },
  {
    name: "admin-settings-marketing-sms",
    path: "/admin/settings/marketing/sms",
    meta: smskyW5LnG3TOMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/settings/marketing/sms.vue")
  },
  {
    name: "admin-settings-marketing-whatsapp",
    path: "/admin/settings/marketing/whatsapp",
    meta: whatsapphWxdIE6wm3Meta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/settings/marketing/whatsapp.vue")
  },
  {
    name: "admin-sponsor-id",
    path: "/admin/sponsor/:id()",
    meta: indexOZh5tZqtnHMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/sponsor/[id]/index.vue")
  },
  {
    name: "admin-sponsor-add",
    path: "/admin/sponsor/add",
    meta: addaknkjElNcpMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/sponsor/add.vue")
  },
  {
    name: "admin-sponsor",
    path: "/admin/sponsor",
    meta: indexCpr9fb9EdjMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/sponsor/index.vue")
  },
  {
    name: "admin-user-id",
    path: "/admin/user/:id()",
    meta: indexUyOdpw2AKxMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/user/[id]/index.vue")
  },
  {
    name: "admin-user-add",
    path: "/admin/user/add",
    meta: add3W9zM3RWHaMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/user/add.vue")
  },
  {
    name: "admin-user",
    path: "/admin/user",
    meta: indexuuW53REQQOMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/admin/user/index.vue")
  },
  {
    name: "archive",
    path: "/archive",
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/archive.vue")
  },
  {
    name: "checkout-cancel",
    path: "/checkout/cancel",
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/checkout/cancel.vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: indexPr1zlkGStUMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/checkout/index.vue")
  },
  {
    name: "checkout-success",
    path: "/checkout/success",
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/checkout/success.vue")
  },
  {
    name: "client-id-event",
    path: "/client/:id()/event",
    meta: indexDJp9YkDLC3Meta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/client/[id]/event/index.vue")
  },
  {
    name: "client-id",
    path: "/client/:id()",
    meta: index7Fm9eQCC3GMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/client/[id]/index.vue")
  },
  {
    name: "client-id-payouts-add",
    path: "/client/:id()/payouts/add",
    meta: add3FS6LR35l5Meta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/client/[id]/payouts/add.vue")
  },
  {
    name: "client-id-payouts",
    path: "/client/:id()/payouts",
    meta: indexaHvWU0rRZoMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/client/[id]/payouts/index.vue")
  },
  {
    name: "client-id-settings",
    path: "/client/:id()/settings",
    meta: settingsmBJlqEk7nZMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/client/[id]/settings.vue")
  },
  {
    name: "collect",
    path: "/collect",
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/collect.vue")
  },
  {
    name: "event-slug",
    path: "/event/:slug()",
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/event/[slug]/index.vue")
  },
  {
    name: "event-genre",
    path: "/event/genre",
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/event/genre/index.vue")
  },
  {
    name: "event-tag",
    path: "/event/tag",
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/event/tag/index.vue")
  },
  {
    name: "event-type",
    path: "/event/type",
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/event/type/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginn8MiOnwcYGMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/login.vue")
  },
  {
    name: "page-slug",
    path: "/page/:slug()",
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/page/[slug]/index.vue")
  },
  {
    name: "payspot-id",
    path: "/payspot/:id()",
    meta: indexv0fUhMC4peMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/payspot/[id]/index.vue")
  },
  {
    name: "payspot-event-id",
    path: "/payspot/event/:id()",
    meta: index9vM5B6iPQ8Meta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/payspot/event/[id]/index.vue")
  },
  {
    name: "scanner-id",
    path: "/scanner/:id()",
    meta: index41pHAutQ5aMeta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/scanner/[id]/index.vue")
  },
  {
    name: "scanner-support",
    path: "/scanner/support",
    meta: supportSv03IWJtQ2Meta || {},
    component: () => import("/etc/nginx/sites-available/ulaznice.at/pages/scanner/support.vue")
  }
]